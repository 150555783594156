import React from "react";

export default class Agenda extends React.Component {

    render() {
        return (
            <div className="agenda-bg" >
                <h1>
                    Reception
                </h1>
                <img src="./images/heart-line.svg" className="reception-divider" />
                <h1 style={{fontSize: 30}}>
                    October 25<sup>th</sup>, Sunday
                </h1>
                <h3>
                    07:00 PM Onwards
                </h3>

                <img src="./images/ornament-divider-color-blue.svg" style={{marginTop: 20}} />
                <div style={{marginTop: 20}}>
                    <img src="./images/ring.svg" />
                </div>

                <h1>
                    Muhurtham
                </h1>
                <img src="./images/heart-line.svg" className="reception-divider" />
                <h1 style={{fontSize: 30}}>
                    October 26<sup>th</sup>, Monday
                </h1>
                <h3>
                    09:00 AM to 10:30 AM
                </h3>
            </div>
        );
    }

}
