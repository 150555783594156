import React from "react";
import $ from "jquery";
import classNames from 'classnames/bind';
import Wedding from './Wedding';
import Venue from './Venue';
import Agenda from './Agenda';

export default class ImageSlider extends React.Component {
    constructor(props) {
        super(props);

        this.IMAGE_PARTS = 4;

        this.changeTO = null;
        this.AUTOCHANGE_TIME = 6000;

        this.state = { activeSlide: -1, prevSlide: -1, sliderReady: false };
    }

    componentWillUnmount() {
        window.clearTimeout(this.changeTO);
    }

    componentDidMount() {
        this.runAutochangeTO();
        setTimeout(() => {
            this.setState({ activeSlide: 0, sliderReady: true });
        }, 0);
        this.currentId = null;
        this.currentTab = null;
        this.tabContainerHeight = 70;
        let self = this;
        $('.et-hero-tab').click(function(event) {
            self.onTabClick(event, $(this));
        });
        $(window).scroll(() => { this.onScroll(); });
        $(window).resize(() => { this.onResize(); });
    }

    runAutochangeTO() {
        this.changeTO = setTimeout(() => {
            this.changeSlides(1);
            this.runAutochangeTO();
        }, this.AUTOCHANGE_TIME);
    }

    changeSlides(change) {
        window.clearTimeout(this.changeTO);
        const { length } = this.props.slides;
        const prevSlide = this.state.activeSlide;
        let activeSlide = prevSlide + change;
        if (activeSlide < 0) activeSlide = length - 1;
        if (activeSlide >= length) activeSlide = 0;
        this.setState({ activeSlide, prevSlide });
    }

    onTabClick(event, element) {
        event.preventDefault();
        let scrollTop = $(element.attr('href')).offset().top - this.tabContainerHeight + 1;
        $('html, body').animate({ scrollTop: scrollTop }, 600);
    }

    onScroll() {
        this.checkTabContainerPosition();
        this.findCurrentTabSelector();
    }

    onResize() {
        if(this.currentId) {
            this.setSliderCss();
        }
    }

    checkTabContainerPosition() {
        let offset = $('.et-hero-tabs').offset().top + $('.et-hero-tabs').height() - this.tabContainerHeight;
        if($(window).scrollTop() > offset) {
            $('.et-hero-tabs-container').addClass('et-hero-tabs-container--top');
        }
        else {
            $('.et-hero-tabs-container').removeClass('et-hero-tabs-container--top');
        }
    }

    findCurrentTabSelector(element) {
        let newCurrentId;
        let newCurrentTab;
        let self = this;
        $('.et-hero-tab').each(function() {
            let id = $(this).attr('href');
            let offsetTop = $(id).offset().top - self.tabContainerHeight;
            let offsetBottom = $(id).offset().top + $(id).height() - self.tabContainerHeight;
            if($(window).scrollTop() > offsetTop && $(window).scrollTop() < offsetBottom) {
                newCurrentId = id;
                newCurrentTab = $(this);
            }
        });
        if(this.currentId != newCurrentId || this.currentId === null) {
            this.currentId = newCurrentId;
            this.currentTab = newCurrentTab;
            this.setSliderCss();
        }
    }

    setSliderCss() {
        let width = 0;
        let left = 0;
        if(this.currentTab) {
            width = this.currentTab.css('width');
            left = this.currentTab.offset().left;
        }
        $('.et-hero-tab-slider').css('width', width);
        $('.et-hero-tab-slider').css('left', left);
    }

    render() {
        const { activeSlide, prevSlide, sliderReady } = this.state;
        return (
            <div className='sticky-navigation'>
                <section className="et-hero-tabs">
                    <div className={classNames('slider', { 's--ready': sliderReady })}>
                        <div className="slider__slides">

                            {
                                // Slide 0
                            }
                            <div
                                className={classNames('slider__slide', { 's--active': activeSlide === 0, 's--prev': prevSlide === 0  })}
                                key={0}
                            >
                                <div>
                                    <div className="slider__slide-content">
                                        <img src='./images/decorator-circle.svg' className="slider__slide-overlay img-anim" />
                                        <p className="slider__slide-subheading wedding-anim">THE WEDDING OF</p>
                                        <img src='./images/ornament-divider.svg' className="slider__slide-divider"  />
                                        <p className="slider__slide-heading groom-anim">
                                            {'MADHAVAN'.split('').map((l, i) => <span key={i}>{l}</span>)}
                                        </p>
                                        <p className="slider__slide-heading amp-anim">
                                            &
                                        </p>
                                        <p className="slider__slide-heading bride-anim">
                                            {'SUMATHY'.split('').map((l, i) => <span key={i}>{l}</span>)}
                                        </p>

                                    </div>
                                </div>
                                <div className="slider__slide-parts">
                                    {[...Array(this.IMAGE_PARTS).fill()].map((x, i) => (
                                        <div className="slider__slide-part" key={i}>
                                            <div className="slider__slide-part-inner" style={{ backgroundImage: `url(https://media.istockphoto.com/photos/hindu-wedding-picture-id831050036?k=6&m=831050036&s=612x612&w=0&h=OWJjAWTlAY0goJd0kQFTto7m8jfb2F4MAsRyKotZ6ZA=)` }} />
                                        </div>
                                    ))}
                                </div>
                            </div>
                            {
                                // Slide 1
                            }
                                <div
                                    className={classNames('slider__slide', { 's--active': activeSlide === 1, 's--prev': prevSlide === 1  })}
                                    key={1}
                                >
                                    <div>
                                        <div className="slider__slide-content">
                                            <img src='images/decorator-box.svg' className="slider__slide-img-anim-2" />
                                            <h3 className="slider__slide-subheading img-2-subheading">The Big Day</h3>
                                            <div className="slider__slide-img-anim-2-split-wrapper">
                                                <div className="slider__slide-img-anim-2-split-rl"/>
                                            </div>
                                            <h2 className="slider__slide-heading img-2-heading">
                                                {'26, O'.split('').map((l, i) => <span key={i}>{l}</span>)}
                                                {'ct, 2020'.split('').map((l, i) => <span style={{textTransform: 'lowercase'}} key={i}>{l}</span>)}
                                            </h2>
                                            <div className="slider__slide-img-anim-2-split-wrapper">
                                                <div className="slider__slide-img-anim-2-split-lr" />
                                            </div>
                                            <h3 className="slider__slide-subheading img-2-subheading-2">Choolaimedu, Chennai</h3>

                                        </div>
                                    </div>
                                    <div className="slider__slide-parts">
                                        {[...Array(this.IMAGE_PARTS).fill()].map((x, i) => (
                                            <div className="slider__slide-part" key={i}>
                                                <div className="slider__slide-part-inner" style={{ backgroundImage: `url(https://smash-images.photobox.com/resized/017a8a36ac6d41118d2a1fc7ba567b830f88e9e6_UK-Easel-Calendar-Portrait2-Range-lifestyle-gallery-2240x1755-1620-@2x.jpg)` }} />
                                            </div>
                                        ))}
                                    </div>
                                </div>

                            {
                                // Slide 2
                            }
                            <div
                                className={classNames('slider__slide', { 's--active': activeSlide === 2, 's--prev': prevSlide === 2  })}
                                key={2}
                            >
                                <div>
                                    <div className="slider__slide-content">
                                        <img src='images/decorator-top.svg' className="slider__slide-img-anim-3-top" />
                                        <h3 className="slider__slide-subheading img-3-subheading img-3-subheading-first">Expecting you and your blessings,</h3>
                                        <h3 className="slider__slide-subheading img-3-subheading img-3-subheading-second">both are required for our wedding ceremony</h3>
                                        <img src='images/decorator-bottom.svg' className="slider__slide-img-anim-3-bottom" />

                                    </div>
                                </div>
                                <div className="slider__slide-parts">
                                    {[...Array(this.IMAGE_PARTS).fill()].map((x, i) => (
                                        <div className="slider__slide-part" key={i}>
                                            <div className="slider__slide-part-inner" style={{ backgroundImage: `url(https://images.template.net/wp-content/uploads/2016/06/08111744/Wedding-Backgrounds.jpg)` }} />
                                        </div>
                                    ))}
                                </div>
                            </div>


                        </div>
                        <div className="slider__control" onClick={() => this.changeSlides(-1)} />
                        <div className="slider__control slider__control--right" onClick={() => this.changeSlides(1)} />
                    </div>
                    <div style={{height: 70}} />

                    <div className="et-hero-tabs-container">
                        <a className="et-hero-tab" href="#tab-THE-WEDDING">THE WEDDING</a>
                        <a className="et-hero-tab" href="#tab-AGENDA">AGENDA</a>
                        <a className="et-hero-tab" href="#tab-VENUE">VENUE</a>
                        <span className="et-hero-tab-slider" />
                    </div>
                </section>
                <main className="et-main">
                    <Wedding />
                    <section className="et-slide agenda" id="tab-AGENDA">
                        <Agenda />
                    </section>
                    <section className="et-slide venue" id="tab-VENUE">
                        <Venue />
                    </section>

                </main>
            </div>
        );
    }
}
