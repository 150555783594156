export function firebaseInit() {
    const firebaseConfig = {
        apiKey: "AIzaSyCJYRKNQzWRYOuFYXL9wzbxa7Fb7VYeJUY",
        authDomain: "madhavan-weds-sumathy.firebaseapp.com",
        databaseURL: "https://madhavan-weds-sumathy.firebaseio.com",
        projectId: "madhavan-weds-sumathy",
        storageBucket: "madhavan-weds-sumathy.appspot.com",
        messagingSenderId: "558598525936",
        appId: "1:558598525936:web:945de732d4b1ad77b20335",
        measurementId: "G-Y4N1DB6CCE"
    };
    // Initialize Firebase
    if (!firebase.apps.length) {
        firebase.initializeApp(firebaseConfig);
        firebase.analytics().setUserProperties({device: window.mobileOs});
        const messaging = firebase.messaging();
        firebaseRegister(messaging);
        firebaseMessageListen(messaging);
        // firebaseBackgroundMessageListen();
    }
}

function firebaseRegister(messaging) {
    messaging.usePublicVapidKey('BHwhSDdcboR8gaH6gPGpvu9MYH4Lo52kEtr69Vdk0RHFDu2GszIjxNzMUuNbRcUntI9uuS_IXvYKnZUek-lvVSw');
    messaging.getToken().then((currentToken) => {
    }).catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
    });
}

function firebaseMessageListen(messaging) {
    messaging.onMessage((payload) => {
        console.log('Message received. ', payload);
    });
}

function firebaseBackgroundMessageListen() {
    messaging.setBackgroundMessageHandler(function (payload) {
        console.log('[firebase-messaging-sw.js] Received background message ', payload);
        // Customize notification here
        const notificationTitle = 'Background Message Title';
        const notificationOptions = {
            body: 'Background Message body.',
            icon: '/firebase-logo.png'
        };

        return self.registration.showNotification(notificationTitle,
            notificationOptions);
    });
}
