import React from "react";
import NotificationsIcon from '@material-ui/icons/Notifications';
import Dialog from "@material-ui/core/Dialog/Dialog"
import IconButton from '@material-ui/core/IconButton';
import Fab from '@material-ui/core/Fab';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import blue from '@material-ui/core/colors/blue';

export default class NotificationPermission extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            permission: '',
            popup: false
        }
    }

    componentDidMount() {
        this.checkNotificationPermission();
    }

    checkNotificationPermission() {
        if (!("Notification" in window)) {
            this.setState({permission: "not-supported"});
        }

        else if (Notification.permission === "granted") {
            this.setState({permission: "granted"});
        }

        else if (Notification.permission === "denied") {
            this.setState({permission: "denied"});
        }

        else if (Notification.permission !== "granted") {
            this.requestNotification();
        }

        else if (this.state.permission === "") {
            this.setState({popup: true})
        }
    }

    requestNotification() {
        this.setState({popup: false});
        Notification.requestPermission().then((permission) => {
            this.setState({permission: permission});
        });
    }

    requestNotificationPopup() {
        this.setState({popup: true})

    }

    handleClose() {
        this.setState({popup: false})
    }

    render() {
        return (
            <div>
                {
                    this.state.permission !== 'granted' &&
                    <Fab onClick={this.requestNotificationPopup.bind(this)}
                         style={{
                             backgroundColor: '#FFF',
                             width: 40,
                             height: 40,
                             marginBottom: 20,
                             right: 20
                         }}
                    >
                        <NotificationsIcon style={{color: blue.A400}}/>
                    </Fab>
                }
                <Dialog open={this.state.popup}
                        onClose={this.handleClose.bind(this)}>
                    <DialogTitle id="alert-dialog-title">Please enable notification permission to receive notifications</DialogTitle>

                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            We will keep you updated on the details of the event and keep you reminded.
                        </DialogContentText>
                    </DialogContent>

                    <DialogActions>
                        <Button onClick={this.requestNotification.bind(this)} color="primary">
                            Okay
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }

}
