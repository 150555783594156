import React from "react";
import TimeLeft from './TimeLeft';

export default class Wedding extends React.Component {

    render() {
        return <section className="et-slide our-wedding"
                        id="tab-THE-WEDDING">
            <div style={{marginTop: -70}}>
                <div className="wedding-quote">
                    <h3>Love</h3><img src="./images/heart.svg" width={20} height={20} /><h3>Laughter</h3><img src="./images/heart.svg" width={20} height={20} /><h3>And happily ever after</h3>
                </div>
                <img src="./images/rose.svg" />
                <h3>
                    <span>
                        THE BIG DAY
                    </span>
                </h3>
                <h1 style={{marginTop: 20}}>
                    26<sup>th</sup>, October 2020
                </h1>
                <img src="./images/ornament-divider-color.svg" style={{marginTop: 20, marginBottom: 20}} />
                <p style={{margin: '0 10%'}}>
                    We are so excited to celebrate our special day with our family and friends.
                </p>
            </div>
            <TimeLeft />
        </section>
    }

}
