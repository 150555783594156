import React from "react";

export default class RefreshComponent extends React.Component {

    render() {
        return <div id="divLoader">
            New content is available, page will refresh in <span id="refreshCountdown" /> seconds
        </div>
    }

}
