import React from "react";
import icsFormatter from '../icsFormatter';
import Dialog from '@material-ui/core/Dialog/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';

export default class AddToCalendar extends React.Component {

    KEY = 'addEventToCalendar';

    constructor(props) {
        super(props);
        this.state = {
            popup: false
        }
    }

    componentDidMount() {
        this.checkEventAdded();
    }

    checkEventAdded() {
        if (!localStorage.getItem(this.KEY)) {
            this.requestDownloadPermission();
        }
    }
    requestDownloadPermission() {
        this.setState({popup: true})

    }

    handleClose() {
        this.setState({popup: false})
    }

    addEvent(){
        this.handleClose();
        const calEntry = icsFormatter();

        const title = 'Madhavan-Sumathy-Reception';
        const place = '';
        const begin = new Date('2020-10-25 19:00:00');
        const end = new Date(new Date('2020-10-25 21:00:00'));

        const description = '';

        calEntry.addEvent(title,description, place, begin.toUTCString(), end.toUTCString());
        calEntry.download('Madhavan-Weds-Sumathy');
        window.localStorage.setItem(this.KEY, "true");
    }

    render() {
        return (
            <div>
                <Dialog open={this.state.popup}
                        onClose={this.handleClose.bind(this)}>
                    <DialogTitle id="alert-dialog-title">Add event reminder to your google calendar</DialogTitle>

                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Allow pop-up to download files for adding the event reminder to your google calendar. (Open the file once download is complete)
                        </DialogContentText>
                    </DialogContent>

                    <DialogActions>
                        <Button onClick={this.addEvent.bind(this)} color="primary">
                            Okay
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }

}
