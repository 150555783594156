import React from "react";
import moment from 'moment';

export default class TimeLeft extends React.Component {

    date = moment("26-10-2020 09:30:00", "DD-MM-YYYY HH:mm:ss");
    intervalFunction;

    constructor(props) {
        super(props);
        this.state = {
            currentMoment: moment()
        }
    }

    componentDidMount() {
        this.intervalFunction = setInterval(() => this.setState({
            currentMoment: moment()
        }), 1000);
    }

    componentWillUnmount() {
        clearInterval(this.intervalFunction);
    }

    render() {
        const date = moment(this.date, "DD-MM-YYYY HH:mm:ss");
        const days = date.diff(this.state.currentMoment, 'd');
        const hours = date.subtract(days, 'd')
                            .diff(this.state.currentMoment, 'h');
        const minutes = date.subtract(hours, 'h')
                            .diff(this.state.currentMoment, 'm');
        const seconds = date.subtract(minutes, 'm')
                            .diff(this.state.currentMoment, 's');
        return <div className="timer-wrapper">
            <img src="./images/combined-shape-top.svg" style={{marginTop: -26}} />
            <div className="timer">
                <div>
                    <h1>
                        {days}
                    </h1>
                    <h3>
                        Days
                    </h3>
                </div>
                <div>
                    <h1>
                        {hours}
                    </h1>
                    <h3>
                        Hours
                    </h3>
                </div>
                <div>
                    <h1>
                        {minutes}
                    </h1>
                    <h3>
                        Minutes
                    </h3>
                </div>
                <div>
                    <h1>
                        {seconds}
                    </h1>
                    <h3>
                        Seconds
                    </h3>
                </div>
            </div>
            <img src="./images/combined-shape-bottom.svg" style={{marginBottom: -26}} />
        </div>
    }

}
