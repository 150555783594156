import React from "react";
import Fab from '@material-ui/core/Fab';
import IconButton from '@material-ui/core/IconButton';
import AddToHomeScreenIcon from '@material-ui/icons/AddToHomeScreen';
import blue from '@material-ui/core/colors/blue';
import Dialog from '@material-ui/core/Dialog/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';

export default class AddToHomeScreen extends React.Component {

    KEY = 'addToHomeScreenRequest';
    deferredPrompt;

    constructor(props) {
        super(props);
        this.state = {
            installed: false,
            popup: false
        };
    }

    componentDidMount() {
        this.beforeInstallPromptListener();
        this.appInstallationListener();
        this.domContentLoadedListener();
    }

    beforeInstallPromptListener() {
        window.addEventListener('beforeinstallprompt', (e) => {
            e.preventDefault();
            this.deferredPrompt = e;
            if(!localStorage.getItem(this.KEY)) {
                this.requestAddToHomeScreenPopup();
            }
        });
    }

    appInstallationListener() {
        window.addEventListener('appinstalled', (evt) => {
            // Log install to analytics
            analytics.logEvent('PWA', {installation: 'installed'})
            this.setState({
                installed: true
            });
        });
    }

    domContentLoadedListener() {
        window.addEventListener('DOMContentLoaded', () => {
            let displayMode = 'browser tab';
            if (navigator.standalone) {
                displayMode = 'standalone-ios';
            }
            if (window.matchMedia('(display-mode: standalone)').matches) {
                displayMode = 'standalone';
            }
            // Log launch display mode to analytics
            analytics.logEvent('DISPLAY_MODE_LAUNCH:', {displayMode: displayMode});
            if(displayMode !== 'browser tab') {
                this.setState({
                    installed: true
                });
            }
            window.matchMedia('(display-mode: standalone)').addListener((evt) => {
                let displayMode = 'browser tab';
                if (evt.matches) {
                    displayMode = 'standalone';
                }
                // Log display mode change to analytics
                analytics.logEvent('DISPLAY_MODE_CHANGED', {displayMode: displayMode});
            });
        });
    }

    requestAddToHomeScreen() {
        this.setState({popup: false});
        if(this.deferredPrompt) {
            this.deferredPrompt.prompt();
            this.deferredPrompt.userChoice.then((choiceResult) => {
                if (choiceResult.outcome === 'accepted') {
                    analytics.logEvent('PWA', {installation: 'accepted'});
                } else {
                    analytics.logEvent('PWA', {installation: 'rejected'});
                    console.log('User dismissed the install prompt');
                }
            });
        }
    }

    requestAddToHomeScreenPopup() {
        this.setState({popup: true});
        localStorage.setItem(this.KEY, "true");
    }

    handleClose() {
        this.setState({popup: false})
    }

    render() {
        return <div>
            {
                !this.state.installed && window.isMobile && !localStorage.getItem(this.KEY) &&
                <Fab onClick={this.requestAddToHomeScreenPopup.bind(this)}
                     style={{
                         right: 20,
                         width: 40,
                         height: 40,
                         backgroundColor: '#FFF'
                     }}
                >
                    <AddToHomeScreenIcon style={{color: blue.A400}}/>
                </Fab>
            }
            <Dialog open={this.state.popup}
                    onClose={this.handleClose.bind(this)}>
                <DialogTitle id="alert-dialog-title">Add to your home screen</DialogTitle>

                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        By adding this invitation to your home screen, you can access the site in offline and remind yourself about the event
                    </DialogContentText>
                </DialogContent>

                <DialogActions>
                    <Button onClick={this.requestAddToHomeScreen.bind(this)} color="primary">
                        Okay
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    }

}
