import React from "react";
import { render } from "react-dom";
import ImageSlider from './components/ImageSlider';
import slides from './constants/SlideImages';
import './styles/index.scss';
import registerServiceWorker from './registerServiceWorker';
import {
    firebaseInit,
} from './constants/FCMHandler';
import RefreshComponent from './components/RefreshComponent';
import NotificationPermission from './components/NotificationPermission';
import AddToHomeScreen from './components/AddToHomeScreen';
import AddToCalendar from './components/AddToCalendar';

window.isAndroid = navigator.userAgent.toLowerCase().match(/(android)/) !== null;
window.isIOS = navigator.userAgent.toLowerCase().match(/(ipad|iphone|ipod)/) !== null;
window.isMobile = isAndroid || isIOS;
window.mobileOs = (isAndroid && 'android') || (isIOS && "ios") || "browser";

setTimeout(() =>
    render(
      <div>
        <AddToCalendar />
        <div style={{
            position: 'absolute',
            top: 20,
            right: 20,
            zIndex: 10,
        }}>
            <NotificationPermission />
            <AddToHomeScreen />
        </div>
        <RefreshComponent />
        <ImageSlider slides={slides} />
      </div>,
      document.getElementById("root")
    ),
    1000
);

registerServiceWorker();
firebaseInit();
