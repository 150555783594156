import React from "react";

export default class Venue extends React.Component {

    render() {
        return <div className="map-container">
            <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3886.610380488399!2d80.21379981489025!3d13.060454316455893!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a5266938368ab11%3A0x5ad7ea09aba83503!2sMaharajapuram%20Nadar%20Sangam%20Jayamani%20Pandia%20Nadar%20Inn!5e0!3m2!1sen!2sin!4v1602445842721!5m2!1sen!2sin?key=AIzaSyCf8Whwn0pk4ucIh5T3wRPnm9bidli9MG8"
                width="95%"
                height="80%"
                frameBorder="0" style={{border: 0}} allowFullScreen="" aria-hidden="false" tabIndex="0"/>
                <div className="address">
                    <img src="./images/combined-shape-white-top.svg" style={{marginTop: -42}} />
                    <h1 style={{marginTop: 15}}>
                        Location
                    </h1>
                    <img src="./images/ornament-divider-color.svg" style={{marginTop: 20, marginBottom: 20}} />
                    <h3>
                        Maharajapuram Nadar Sangam Jayamani Pandia Nadar Inn
                    </h3>
                    <h5 style={{marginBottom: 50}}>
                        76, Elango Adigal St, Padmanabha Nagar, Choolaimedu, Chennai, Tamil Nadu 600094 (Near MMDA Bus stop)
                    </h5>
                    <div style={{ marginTop: 15,marginBottom: -16}}>
                        <img src="./images/combined-shape-white-bottom.svg" style={{marginBottom: -58}} />
                    </div>
                </div>
            <h1 style={{margin: '330px 0 50px 0', textAlign: 'center'}}>
                The journey begins....
            </h1>
        </div>
    }

}
